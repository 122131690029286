import * as _ethers2 from "ethers";
var _ethers = "default" in _ethers2 ? _ethers2.default : _ethers2;
var exports = {};
var __extends = exports && exports.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
exports.__esModule = true;
exports.SenderCreator__factory = void 0;
/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
var ethers_1 = _ethers;
var _abi = [{
  inputs: [{
    internalType: "bytes",
    name: "initCode",
    type: "bytes"
  }],
  name: "createSender",
  outputs: [{
    internalType: "address",
    name: "sender",
    type: "address"
  }],
  stateMutability: "nonpayable",
  type: "function"
}];
var _bytecode = "0x608060405234801561001057600080fd5b50610213806100206000396000f3fe608060405234801561001057600080fd5b506004361061002b5760003560e01c8063570e1a3614610030575b600080fd5b61004361003e3660046100f9565b61006c565b60405173ffffffffffffffffffffffffffffffffffffffff909116815260200160405180910390f35b60008061007c601482858761016b565b61008591610195565b60601c90506000610099846014818861016b565b8080601f016020809104026020016040519081016040528093929190818152602001838380828437600092018290525084519495509360209350849250905082850182875af190506000519350806100f057600093505b50505092915050565b6000806020838503121561010c57600080fd5b823567ffffffffffffffff8082111561012457600080fd5b818501915085601f83011261013857600080fd5b81358181111561014757600080fd5b86602082850101111561015957600080fd5b60209290920196919550909350505050565b6000808585111561017b57600080fd5b8386111561018857600080fd5b5050820193919092039150565b7fffffffffffffffffffffffffffffffffffffffff00000000000000000000000081358181169160148510156101d55780818660140360031b1b83161692505b50509291505056fea2646970667358221220d5d7cb55b3123d86b2710028be2074a469cca162ad2a26c50fb1cfdce4424b9464736f6c634300080f0033";
var isSuperArgs = function (xs) {
  return xs.length > 1;
};
var SenderCreator__factory = /** @class */function (_super) {
  __extends(SenderCreator__factory, _super);
  function SenderCreator__factory() {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      args[_i] = arguments[_i];
    }
    var _this = this;
    if (isSuperArgs(args)) {
      _this = _super.apply(this, args) || this;
    } else {
      _this = _super.call(this, _abi, _bytecode, args[0]) || this;
    }
    return _this;
  }
  SenderCreator__factory.prototype.deploy = function (overrides) {
    return _super.prototype.deploy.call(this, overrides || {});
  };
  SenderCreator__factory.prototype.getDeployTransaction = function (overrides) {
    return _super.prototype.getDeployTransaction.call(this, overrides || {});
  };
  SenderCreator__factory.prototype.attach = function (address) {
    return _super.prototype.attach.call(this, address);
  };
  SenderCreator__factory.prototype.connect = function (signer) {
    return _super.prototype.connect.call(this, signer);
  };
  SenderCreator__factory.createInterface = function () {
    return new ethers_1.utils.Interface(_abi);
  };
  SenderCreator__factory.connect = function (address, signerOrProvider) {
    return new ethers_1.Contract(address, _abi, signerOrProvider);
  };
  SenderCreator__factory.bytecode = _bytecode;
  SenderCreator__factory.abi = _abi;
  return SenderCreator__factory;
}(ethers_1.ContractFactory);
exports.SenderCreator__factory = SenderCreator__factory;
export default exports;