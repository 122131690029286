import * as _ethers2 from "ethers";
var _ethers = "default" in _ethers2 ? _ethers2.default : _ethers2;
var exports = {};
/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
exports.__esModule = true;
exports.IStakeManager__factory = void 0;
var ethers_1 = _ethers;
var _abi = [{
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: "address",
    name: "account",
    type: "address"
  }, {
    indexed: false,
    internalType: "uint256",
    name: "totalDeposit",
    type: "uint256"
  }],
  name: "Deposited",
  type: "event"
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: "address",
    name: "account",
    type: "address"
  }, {
    indexed: false,
    internalType: "uint256",
    name: "totalStaked",
    type: "uint256"
  }, {
    indexed: false,
    internalType: "uint256",
    name: "unstakeDelaySec",
    type: "uint256"
  }],
  name: "StakeLocked",
  type: "event"
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: "address",
    name: "account",
    type: "address"
  }, {
    indexed: false,
    internalType: "uint256",
    name: "withdrawTime",
    type: "uint256"
  }],
  name: "StakeUnlocked",
  type: "event"
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: "address",
    name: "account",
    type: "address"
  }, {
    indexed: false,
    internalType: "address",
    name: "withdrawAddress",
    type: "address"
  }, {
    indexed: false,
    internalType: "uint256",
    name: "amount",
    type: "uint256"
  }],
  name: "StakeWithdrawn",
  type: "event"
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: "address",
    name: "account",
    type: "address"
  }, {
    indexed: false,
    internalType: "address",
    name: "withdrawAddress",
    type: "address"
  }, {
    indexed: false,
    internalType: "uint256",
    name: "amount",
    type: "uint256"
  }],
  name: "Withdrawn",
  type: "event"
}, {
  inputs: [{
    internalType: "uint32",
    name: "_unstakeDelaySec",
    type: "uint32"
  }],
  name: "addStake",
  outputs: [],
  stateMutability: "payable",
  type: "function"
}, {
  inputs: [{
    internalType: "address",
    name: "account",
    type: "address"
  }],
  name: "balanceOf",
  outputs: [{
    internalType: "uint256",
    name: "",
    type: "uint256"
  }],
  stateMutability: "view",
  type: "function"
}, {
  inputs: [{
    internalType: "address",
    name: "account",
    type: "address"
  }],
  name: "depositTo",
  outputs: [],
  stateMutability: "payable",
  type: "function"
}, {
  inputs: [{
    internalType: "address",
    name: "account",
    type: "address"
  }],
  name: "getDepositInfo",
  outputs: [{
    components: [{
      internalType: "uint112",
      name: "deposit",
      type: "uint112"
    }, {
      internalType: "bool",
      name: "staked",
      type: "bool"
    }, {
      internalType: "uint112",
      name: "stake",
      type: "uint112"
    }, {
      internalType: "uint32",
      name: "unstakeDelaySec",
      type: "uint32"
    }, {
      internalType: "uint48",
      name: "withdrawTime",
      type: "uint48"
    }],
    internalType: "struct IStakeManager.DepositInfo",
    name: "info",
    type: "tuple"
  }],
  stateMutability: "view",
  type: "function"
}, {
  inputs: [],
  name: "unlockStake",
  outputs: [],
  stateMutability: "nonpayable",
  type: "function"
}, {
  inputs: [{
    internalType: "address payable",
    name: "withdrawAddress",
    type: "address"
  }],
  name: "withdrawStake",
  outputs: [],
  stateMutability: "nonpayable",
  type: "function"
}, {
  inputs: [{
    internalType: "address payable",
    name: "withdrawAddress",
    type: "address"
  }, {
    internalType: "uint256",
    name: "withdrawAmount",
    type: "uint256"
  }],
  name: "withdrawTo",
  outputs: [],
  stateMutability: "nonpayable",
  type: "function"
}];
var IStakeManager__factory = /** @class */function () {
  function IStakeManager__factory() {}
  IStakeManager__factory.createInterface = function () {
    return new ethers_1.utils.Interface(_abi);
  };
  IStakeManager__factory.connect = function (address, signerOrProvider) {
    return new ethers_1.Contract(address, _abi, signerOrProvider);
  };
  IStakeManager__factory.abi = _abi;
  return IStakeManager__factory;
}();
exports.IStakeManager__factory = IStakeManager__factory;
export default exports;